import React, { useEffect } from "react";
import AboutHero from "../sections/AboutHero/AboutHero";
import FAQs from "../sections/FAQs/FAQs";
import Footer from "../sections/Footer/Footer";
import { Helmet } from "react-helmet";
import Navbar from "../sections/Navbar/Navbar";
import OurVision from "../sections/OurVision/OurVision";
import Team from "../sections/Team/Team";
import "../fonts/stylesheet.css";
import { Center } from "@chakra-ui/react";

// styles
const text = {
  fontFamily: "Manrope",
};

// markup
const NewsletterPage = () => {
  useEffect(() => {
    // Substack Feed Widget
    const substackFeedWidget = document.createElement("script");
    substackFeedWidget.async = true;
    substackFeedWidget.src = "https://substackapi.com/embeds/feed.js";
    substackFeedWidget.setAttribute("data-timestamp", String(Date.now()));
    document.head.appendChild(substackFeedWidget);

    window.SubstackFeedWidget = {
      substackUrl: "conquestbitspilani.substack.com",
      posts: 4
    };

    // Custom Substack Widget
    const customSubstackWidget = document.createElement("script");
    customSubstackWidget.async = true;
    customSubstackWidget.src = "https://substackapi.com/widget.js";
    customSubstackWidget.setAttribute("data-timestamp", String(Date.now()));
    document.head.appendChild(customSubstackWidget);

    window.CustomSubstackWidget = {
      substackUrl: "conquestbitspilani.substack.com",
      placeholder: "example@mail.com",
      buttonText: "Subscribe to our Newsletter",
      theme: "custom",
    colors: {
      primary: "#FB723D",
      input: "#FFFFFF",
      email: "#000000",
      text: "#FFFFFF",
    }
    };
  }, []);

  return (
    <main style={text}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Conquest: India's First and Largest Student-run Startup Accelerator
        </title>
      </Helmet>
      <Navbar />
      <br></br>
      <br></br>
      <br></br>
      <div id="substack-feed-embed"></div>
      <br></br>
      <br></br>
      <br></br>
      <Center><div id="custom-substack-embed"></div></Center>
      <br></br>
      <br></br>
      <br></br>
      <Footer />
    </main>
  );
};

export default NewsletterPage;
